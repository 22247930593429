import {
  D_FIND_COUNTRY_CURRENCIES,
  D_GET_TRANSPORTATION_OWNER, D_REGISTER_TRANSPORTATION_OWNER,
  D_UPDATE_TRANSPORTATION_OWNER
} from '../../../utilities/action-types'

import { formatDate, formatTime } from '@smarttransit/common'
import { C_ADD_SITE_ALERT } from '../../../utilities/mutation-types'
import { initBreadcrumbs } from '../../../utilities/helpers'
import _cloneDeep from 'lodash'
import _isEqual from 'lodash.isequal'
import _isNil from 'lodash.isnil'

const ROUTE_NAME = 'transportation-account'

export default {
  props: {
    signedInUser: Object,
    accountId: Number,
    selectedAccount: Object,
    setAsSelectedAccount: Function,
    hasUserType: Function,
    hasTransportationRole: Function,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      name: ROUTE_NAME,
      currentAccount: null,
      currentAccountOriginal: null,
      apiInProgress: false,
      currentAccountError: null,
      selectedCountry: null,
      breadcrumbs: []
    }
  },
  mounted: function () {
    this.selectedAccountWatchHandle = this.$watch(() => { return this.$props.selectedAccount }, (newVal) => {
      this.breadcrumbs = initBreadcrumbs.call(this)
    })

    function onRouterLoad () {
      if (this.$router.currentRoute.name === ROUTE_NAME) {
        const { accountId, hasUserType, selectedAccount, setAsSelectedAccount } = this.$props
        this.apiInProgress = true

        this.$store.dispatch(D_FIND_COUNTRY_CURRENCIES).then(async (countries) => {
          this.countries = countries.map(o => ({ text: o.countryLabel, value: o }))
          if (accountId && accountId > 0) {
            await this.getAccount(accountId)
            if (!selectedAccount) {
              setAsSelectedAccount(this.currentAccount)
            }
          } else if (hasUserType('admin')) {
            return this.generateAccountTemplate()
          } else {
            throw new Error('Please provide a valid account id')
          }
        }).catch((err) => {
          this.currentAccountError = `Account could not be loaded, error in retrieving country data: ${err && err.error ? err.error.message : JSON.stringify(err)}`
        }).finally(() => {
          this.apiInProgress = false
        })
      } else {
        const { accountId, selectedAccount } = this.$props
        if (selectedAccount && accountId === 0) {
          this.$router.replace({ name: this.$router.currentRoute.name, params: { accountId: this.selectedAccount.id } })
        }
      }
      this.breadcrumbs = initBreadcrumbs.bind(this)()
    }
    this.$nextTick(onRouterLoad.bind(this))
  },
  computed: {
    currentAccountLabel () {
      let label = ''
      let routeTitle = ''
      if (this.$router.currentRoute.name === ROUTE_NAME) {
        if (this.currentAccount) {
          label = this.currentAccount.id ? this.currentAccount.companyName : 'Create an Account'
        }
      } else {
        label = this.$props.selectedAccount ? this.$props.selectedAccount.companyName : ''
        routeTitle = ': ' + this.$router.currentRoute.meta.title
      }
      return label + routeTitle
    }
  },
  methods: {
    isDataEdited () {
      return !_isEqual(this.currentAccount, this.currentAccountOriginal)
    },
    save () {
      if (this.currentAccount.id) {
        this.updateAccount()
      } else {
        this.createAccount()
      }
    },
    updateAccount () {
      if (this.validForm() && this.isDataEdited()) {
        this.apiInProgress = true

        this.$store.dispatch(D_UPDATE_TRANSPORTATION_OWNER, {
          id: this.currentAccount.id,
          companyName: this.currentAccount.companyName,
          contactName: this.currentAccount.contactName,
          contactEmail: this.currentAccount.contactEmail,
          contactPhone: this.currentAccount.contactPhone,
          currentCountryId: this.selectedCountry.countryId,
          equipmentTokens: Number(this.currentAccount.equipmentTokens)
        }).then((account) => {
          this.setCurrentAccount(account)

          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Successfully updated account: ${account.companyName}`,
            type: 'success',
            transient: true
          })
        }).catch((err) => {
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Error in updating account: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`,
            type: 'error'
          })
        }).finally(() => {
          this.apiInProgress = false
        })
      }
    },
    createAccount () {
      if (this.validForm() && this.isDataEdited()) {
        if (!this.selectedCountry || !this.selectedCountry.countryId) {
          return alert('Please select a country')
        }
        if (!this.currentAccount.contactPhone || this.currentAccount.contactPhone.indexOf('+') === -1) {
          return alert('Please enter a phone number and select a country code')
        }
        let mobileMoneyPhoneNumber
        if (this.currentAccount.registerMobileMoney) {
          mobileMoneyPhoneNumber = this.currentAccount.mobileMoneyPhoneIsNotContactPhone ? this.currentAccount.mobileMoneyPhoneNumber : this.currentAccount.contactPhone
        }
        if (this.currentAccount.registerMobileMoney && (!mobileMoneyPhoneNumber || !this.currentAccount.moneyAccountTypeId)) {
          return alert('Please provide a mobile money phone number and mobile money account')
        }
        if (this.currentAccount.verificationNotice && !this.currentAccount.verificationType) {
          return alert('Please select a verification type')
        }
        if (!this.currentAccount.verificationNotice && !this.currentAccount.contactPhone && this.currentAccount.verificationType === 'phone') {
          this.currentAccount.verificationType = 'email'
        }
        if (!this.currentAccount.contactPhone && this.currentAccount.verificationType === 'phone') {
          alert('Phone number required to verify by phone')
        } else {
          if (!this.currentAccount.verificationNotice || (this.currentAccount.verificationNotice && confirm('Confirm sending the contact of this new account an email/phone verification message'))) {
            this.apiInProgress = true

            this.$store.dispatch(D_REGISTER_TRANSPORTATION_OWNER, {
              companyName: this.currentAccount.companyName,
              contactName: this.currentAccount.contactName,
              contactEmail: this.currentAccount.contactEmail,
              contactPhone: this.currentAccount.contactPhone,
              currentCountryId: this.selectedCountry.countryId,
              equipmentTokens: Number(this.currentAccount.equipmentTokens),
              verificationType: this.currentAccount.verificationType,
              noVerificationNotice: !this.currentAccount.verificationNotice,
              excludeFeeLabel: this.currentAccount.excludeFeeLabel,
              mobileMoneyPhoneNumber,
              moneyAccountTypeId: this.currentAccount.registerMobileMoney ? this.currentAccount.moneyAccountTypeId : undefined
            }).then((account) => {
              this.$router.push({ params: { accountId: account.id } })
              this.$props.forceRootViewRefresh()

              this.$store.commit(C_ADD_SITE_ALERT, {
                message: `Successfully registered account: ${account.companyName}`,
                type: 'success',
                transient: true
              })
            }).catch((err) => {
              this.$store.commit(C_ADD_SITE_ALERT, {
                message: `Error in registering account: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`,
                type: 'error'
              })
            }).finally(() => {
              this.apiInProgress = false
            })
          }
        }
      } else {
        alert('Please check for invalid fields')
      }
    },
    loadParentView () {
      if (!this.isDataEdited() || confirm('Discard changes made?')) {
        this.$router.push({ name: 'transportation-accounts' })
      }
    },
    generateAccountTemplate () {
      this.selectedCountry = this.countries.find((o) => o.value.countryId === 'GHA')
      this.setCurrentAccount({
        companyName: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        currentCountryId: null,
        equipmentTokens: '3',
        verificationType: 'phone',
        verificationNotice: false
      }, 'doNotSetAsSelectedAccount')
    },
    validForm () {
      return this.$refs && this.$refs.form && this.$refs.form.validate()
    },
    setCurrentAccount (account, doNotSetAsSelectedAccount) {
      this.selectedCountry = null

      if (this.countries.length) {
        const result = this.countries.find((obj) => obj.value.countryId === account.currentCountryId)

        if (result) {
          this.selectedCountry = result.value
        }
      }

      account.isSelectedAccount = Boolean(this.$props.accountId + '' === account.id + '')
      account.dateCreatedLabel = `${formatDate(account.dateCreated)}, ${formatTime(account.dateCreated)}`
      account.dateUpdatedLabel = account.dateUpdated ? `${formatDate(account.dateUpdated)}, ${formatTime(account.dateUpdated)}` : ''
      account.equipmentTokens = !_isNil(account.equipmentTokens) ? account.equipmentTokens + '' : account.equipmentTokens
      account.isEditable = this.hasUserType('admin') || this.hasTransportationRole('OWNER')
      this.currentAccount = account
      this.currentAccountOriginal = _cloneDeep(account)
      if (!doNotSetAsSelectedAccount) {
        this.$props.setAsSelectedAccount(account)
      }
    },
    getAccount (id) {
      this.apiInProgress = true
      return this.$store.dispatch(D_GET_TRANSPORTATION_OWNER, { id }).then((account) => {
        this.setCurrentAccount(account)
      }).catch((err) => {
        this.currentAccountError = `Error in retrieving account: ${err && err.error ? err.error.message : JSON.stringify(err)}`
      }).finally(() => {
        this.apiInProgress = false
      })
    },
    onFilterByCountry (val) {
      this.selectedCountry = val
    }
  },
  beforeDestroy () {
    if (this.selectedAccountWatchHandle) {
      this.selectedAccountWatchHandle()
    }
  }
}
